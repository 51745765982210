<!-- Navbar -->
<section class="bgMagenta p-1 w-100 d-md-block d-none">
  <div class="container d-flex justify-content-around text-white align-items-center">
    <p class="fs-4 fw-bold mb-0">Descuento del mes</p>
    <p class="fs-4 mb-0">🤩</p>
    <p class="fs-4 mb-0">Internet Hogar 600 Megas </p>
    <p class="fs-4 fw-bold mb-0">$16.990 </p>
    <a href="https://catalogo.movistar.cl/servicioshogar/contratacion/datos/index/id/44042?utm_source=google&utm_medium=cpc&utm_campaign=cl_fijo_col-Afiliados_simple_500&utm_term=simple_500&utm_content={keyword}"
      class="btn bg-white colorMagenta p-1 px-4 fs-4 rounded-7 text-capitalize fw-bold">Contratar</a>

  </div>

</section>
<nav class="navbar navbar-expand-lg navbar-dark shadow bgMovistar pb-md-0 sticky-top">
  <!-- Container wrapper -->
  <div class="container d-flex justify-content-between align-items-center py-2">

    <!-- Navbar brand -->
    <a class="navbar-brand fw-light text-dark rounded-5 d-none d-md-block">
      <img src="../../../../assets/img/logo-movistar-white.svg" alt="Movistar_logo" width="200" height="40">
    </a>

    <a class="navbar-brand fw-light text-dark rounded-5 d-block d-md-none logo-movil">
      <img src="../../../../assets/img/LogoMovistarMobile.svg" alt="Movistar_logo" width="100%" height="30">
    </a>


    <div class="containerNav d-none d-md-block" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-lg-0 d-flex flex-row justify-content-center gap-5">
        <!-- Link -->

        <!-- Dropdown -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-bs-auto-close="outside" href="#" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            Hogar
          </a>
          <ul class="dropdown-menu" id="drop">
            <li> <a class="dropdown-item" [routerLink]="['/hogar', 'InternetHogar']">Internet Hogar</a></li>
            <li>
              <a class="dropdown-item" [routerLink]="['PacksDuosStar']">
                Packs Dúos
              </a>
            </li>
            <li>
              <a class="dropdown-item" [routerLink]="['PacksTriosCombo']">Pack Tríos</a>
            </li>
          </ul>

        </li>
        <li class="nav-item">
          <a [routerLink]="['/movil/PlanesIndividuales']" class="nav-link" href="#" id="navbarDropdown" role="button"
            data-mdb-toggle="dropdown" aria-expanded="false">
            Móvil
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['/plan-para-todos/PlanesParaTodos']">Plan para todos</a>
        </li> -->
      </ul>
    </div>
    <div class="w9 d-none d-md-block"></div>


    <div class="containerNav d-block d-md-none" id="navbarSupportedContent">
      <ul class="navbar-nav  mb-lg-0 d-flex flex-row justify-content-center gap-4 me-10">
        <!-- Link -->

        <!-- Dropdown -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-bs-auto-close="outside" href="#" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            Hogar
          </a>
          <ul class="dropdown-menu" id="drop">
            <li> <a class="dropdown-item" [routerLink]="['/hogar', 'InternetHogar']">Internet Hogar</a></li>
            <li>
              <a class="dropdown-item" [routerLink]="['PacksDuosStar']">
                Packs Dúos
              </a>
            </li>
            <li>
              <a class="dropdown-item" [routerLink]="['PacksTriosCombo']">Pack Tríos</a>
            </li>
          </ul>

        </li>
        <li class="nav-item ">
          <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
            [routerLink]="['/movil/PlanesIndividuales']">
            Móvil
          </a>
        </li>
        <!-- <li class="nav-item dropdown">
          <a class="nav-link" [routerLink]="['/plan-para-todos/PlanesParaTodos']" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Plan para todos
          </a>
        </li> -->
      </ul>
    </div>

  </div>
  <!-- Container wrapper -->
</nav>
<!-- Navbar -->

<a href="https://catalogo.movistar.cl/servicioshogar/contratacion/datos/index/id/44042?utm_medium=cpc&utm_source=google&utm_campaign=cl_fijo_col-Afiliados_simple_FO_500&utm_term=14_febrero_2023&utm_content=plan_hogar"
  class="fixed-bottom greenbuttoncontainer">
  <div class="container-fluid">
    <div class="d-flex flex-row justify-content-center align-items-center p-2">
      <div class="d-flex flex-column w-50">
        <h5>Ver Factibilidad</h5>
        <small class="fw-light">Haz clic y revisa aqui</small>
      </div>
      <div>
        <h1 style="font-size: 38px;">👀</h1>
      </div>
    </div>
  </div>
</a>